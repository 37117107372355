import { createSlice } from "@reduxjs/toolkit";

import { SignInWithTwitter } from "../user/userSlice";

export const loadingSlice = createSlice({
  name: "loading",
  initialState: false,
  reducers: {
    toggletLoading: (state) => !state,
    resetLoading: (state) => false,
  },
  extraReducers: {
    [SignInWithTwitter.pending.type]: () => true,
    [SignInWithTwitter.fulfilled.type]: () => false,
    [SignInWithTwitter.rejected.type]: () => false,
  },
});

const { actions, reducer } = loadingSlice;
export const { toggletLoading, resetLoading } = actions;
export default reducer;
