const HomeImages = {
  how_it_work: require("./how_it_work.png"),
  img_right: require("./img_right.png"),
  one_page: require("./one_page.png"),
  white_paper: require("./white_paper.png"),
  ic_bitcoin: require("./ic_bitcoin.png"),
  ic_ether: require("./ic_ether.png"),
  ic_mastercard: require("./ic_mastercard.png"),
  ic_visa: require("./ic_visa.png"),
  what_is_fox: require("./what_is_fox.png"),
  benefits: require("./benefits.png"),
  developing: require("./developing.png"),
  adaptive: require("./adaptive.png"),
  bg_best_features: require("./bg_best_features.png"),
  ic_best_features: require("./ic_best_features.png"),
  fox_token_item_1: require("./fox_token_item_1.png"),
  fox_token_item_2: require("./fox_token_item_2.png"),
  fox_token_item_3: require("./fox_token_item_3.png"),
  fox_token_item_4: require("./fox_token_item_4.png"),
  img_foxmainet: require("./img_foxmainet.png"),
  ic_success_fox_mainet: require("./ic_success_fox_mainet.png"),
  documents_1: require("./documents_1.png"),
  documents_2: require("./documents_2.png"),
  documents_3: require("./documents_3.png"),
  documents_4: require("./documents_4.png"),
  ic_call_faq: require("./ic_call_faq.png"),
  ic_email_faq: require("./ic_email_faq.png"),
  ic_telegram_faq: require("./ic_telegram_faq.png"),
  img_contact: require("./img_contact.png"),
  ic_facebook: require("./ic_facebook.png"),
  ic_bit: require("./ic_bit.png"),
  ic_github: require("./ic_github.png"),
  ic_insta: require("./ic_insta.png"),
  ic_m: require("./ic_m.png"),
  ic_youtube: require("./ic_youtube.png"),
  footer_logo: require("./footer_logo.png"),
  img_right_faq: require("./img_right_faq.png"),
  chart_token_allocation: require("./chart_token_allocation.png"),
  ic_tele: require("./ic_tele.png"),
  ic_tele_gold: require("./ic_tele_gold.png"),
  btn_sign_in: require("./btn_sign_in.png"),
  ic_tele_black: require("./ic_tele_black.png"),
  convert: require("./convert.png"),
  claim: require("./claim.png"),
  img_left_what_is_fox: require("./img_left_what_is_fox.png"),
  bg_where_to_day: require("./bg_where_to_day.png"),
  bg_where_to_day_1: require("./bg_where_to_day_1.png"),
  manta: require("./manta.png"),
};

export default HomeImages;
