import React from "react";
import develop from "src/assets/images/developing.png";

const Wallet = () => {
  return (
    <>
      <div className="col col-md-9">
        <div className="rounded-4 shadow-sm p-3 d-flex align-items-center justify-content-center py-5">
          <img src={develop} width={287} height={281} />
        </div>
      </div>
    </>
  );
};

export default Wallet;
