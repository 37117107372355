import axios from "axios";

// base release
export const API_URL = "https://api.foxcon.io";
/*
  Base client config for your application.
  Here you can define your base url, headers,
  timeouts and middleware used for each request.
*/

const client = axios.create({
  baseURL: API_URL,
  timeout: 100000,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
  responseType: "json",
});

// Custom middleware for requests (this one just logs the error).
client.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log("Failed to make request with error:");
    console.log(error);
    return Promise.reject(error);
  }
);

// Custom middleware for responses (this one just logs the error).
client.interceptors.response.use(
  (response) => {
    if (response.data.status === 401 || response.data.status === 403) {
      console.log(
        `Phiên đăng nhập của bạn đã hết hạn. Vui lòng đăng nhập lại!`
      );
      // client.dispatch(Users.logout())
    }
    return response.data;
  },
  (error) => {
    if (!error.response) {
      throw new Error("Connection error:");
    }

    console.log("Request got response with error:");
    console.log(error);

    return Promise.reject(error?.response?.data);
  }
);

// Set JSON Web Token in Client to be included in all calls
export const setClientToken = (token: string | null) => {
  client.defaults.headers.common.Authorization = !token
    ? ""
    : `Bearer ${token}`;
};
export default client;
