export const CONFIG_PROVIDER = [
  {
    label: "Metamask",
    image: "/images/metamask.png",
  },
  {
    label: "Trust Wallet",
    image: "images/trust-wallet.png",
  },
];
