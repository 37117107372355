import { Col, Modal, Row, message } from "antd";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalConnect } from "../Features/modal/modalSlice";
import { CONFIG_PROVIDER } from "./config";
import styled from "styled-components";
import { LINK_OPEN_METAMASK, LINK_OPEN_TRUST } from "src/constants/constants";
import useConnectWallet from "src/hooks/useConnectWallet";
import { userInfor } from "../Redux/selector";
import InputCopy from "../CoppyItem/InputCopy";
import { logOut, setUserLogin } from "../Features/user/userSlice";
import { setClientToken } from "src/controllers/Client";

const ConnectWalletItem = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.3 all linear;

  :hover {
    border: 1px solid #7a5cff;
  }
`;

const ModalWrapper = styled.div`
  .logout-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;

    button {
      border-radius: 6px;
      border: 3px solid var(--gradient-1, #7a5cff);
      background: #362995;
      padding: 4px 16px 4px 16px;
      color: #fff;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 200% */
      text-transform: capitalize;
      min-width: 120px;

      :hover {
        background-image: linear-gradient(
          to right,
          rgb(99, 94, 193),
          rgb(166, 84, 189)
        );
        border-radius: 4px;
        transition: all 0.4s ease-out;
      }
    }
  }
`;

const ModalConnect = () => {
  const dispatch = useDispatch();
  const userData = useSelector(userInfor);

  const { signAndAccept, isConnectMetamask } = useConnectWallet();

  const { toggle } = useSelector((state: any) => state.modal.modalConnect);

  const handleCancel = () => {
    dispatch(setModalConnect({ toggle: false }));
  };

  const handleLogout = () => {
    dispatch(setUserLogin(null));
    localStorage.removeItem("accessToken");
    setClientToken(null);
    message.success("Logout Success", 3);
    dispatch(setModalConnect({ toggle: false }));
  };

  const isDisable = useMemo(() => {
    return isConnectMetamask;
  }, [isConnectMetamask]);

  const handleConnect = (type: string) => {
    if (isDisable) return;

    if (type === "Metamask") {
      if (!window.ethereum) {
        return window.open(LINK_OPEN_METAMASK);
      }
    }

    if (type === "Trust Wallet") {
      if (!window.ethereum) {
        return window.open(LINK_OPEN_TRUST);
      }
    }

    signAndAccept?.(handleCancel);
  };

  return (
    <Modal
      key="modal-connect"
      className="modal-connect"
      open={toggle}
      centered
      width={450}
      footer={null}
      closeIcon={null}
      onCancel={handleCancel}
    >
      <ModalWrapper>
        <p className="fs-5 fw-bold">
          {userData ? "Your wallet" : "Connect your wallet"}
        </p>

        {!userData ? (
          <div className="row gap-4">
            {CONFIG_PROVIDER.map((item) => (
              <ConnectWalletItem
                key={item?.label}
                className="col-12 d-flex align-items-center gap-3 py-2 px-3"
                onClick={() => handleConnect(item?.label)}
              >
                <img
                  src={item.image}
                  alt={item.label}
                  style={{ width: "48px" }}
                />

                <p className="mb-0 fs-6">{item.label}</p>
              </ConnectWalletItem>
            ))}
          </div>
        ) : (
          <>
            <InputCopy value={userData} />
            <div className="logout-btn">
              <button onClick={handleLogout}>Logout</button>
            </div>
          </>
        )}
      </ModalWrapper>
    </Modal>
  );
};

export default React.memo(ModalConnect);
