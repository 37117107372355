import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { listLeft } from "src/constants/Array";

const ListLeftItems = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const onNavigate = (id: number) => {
    setCurrentIndex(id);
    switch (id) {
      case 0:
        navigate("/");
        break;
      case 1:
        navigate("/affiliate");
        break;
      case 2:
        navigate("/wallet");
        break;

      default:
        break;
    }
  };

  return (
    <div
      className="rounded-4 p-4 shadow-lg col-md-3"
      style={{
        height: 500,
      }}
    >
      {listLeft.map((item) => {
        let checked = false;
        if (item.id === currentIndex) {
          checked = true;
        }
        return (
          <div
            key={item.id}
            className="d-flex align-items-center gap-2 mb-3 p-3 rounded-4"
            onClick={() => onNavigate(item.id)}
            style={{
              background: checked
                ? "linear-gradient(90deg, #6757D4 0%, #190A85 100%)"
                : "transparent",
              color: checked ? "white" : "#363636",
            }}
          >
            <img src={item.icon} width={24} height={24} />
            <div>{item.text}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ListLeftItems;
