const MeetImages = {
    meet_1: require('./1.png'),
    meet_2: require('./2.png'),
    meet_3: require('./3.png'),
    meet_4: require('./4.png'),
    meet_5: require('./5.png'),
    meet_6: require('./6.png'),
    meet_7: require('./7.png'),
    meet_8: require('./8.png'),
    meet_9: require('./9.png'),
    meet_10: require('./10.png'),
}

export default MeetImages