import { UnorderedListOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import logo from "src/assets/images/logo_on_login.png";
import { setModalConnect } from "src/components/Features/modal/modalSlice";
import { userInfor } from "src/components/Redux/selector";
import { formatCode } from "src/helpers";
import { useWeb3React } from "src/packages/wagmi/src";

const HeaderOnLogin = () => {
  const dispatch = useDispatch();
  const userData = useSelector(userInfor);

  const onDisConnect = async () => {
    dispatch(setModalConnect({ toggle: true }));
  };

  return (
    <nav className="navbar navbar-expand-lg container-navbar">
      <div className="container-xl">
        <NavLink to={"/"}>
          <img src={logo} alt="" className="main-logo" />
        </NavLink>
        <div className="w-50 d-none d-lg-block">
          <div className="d-flex justify-content-between w-100">
            <NavLink className="btn-header" to="/">
              What is FOX
            </NavLink>
            <NavLink className="btn-header" to="/">
              Airdrop
            </NavLink>
            <NavLink className="btn-header" to="/">
              Token Sale
            </NavLink>
            <NavLink className="btn-header" to="/">
              Roadmap
            </NavLink>
            <NavLink className="btn-header" to="/">
              Benefits
            </NavLink>
            <NavLink className="btn-header" to="/">
              FAQs
            </NavLink>
            <NavLink className="btn-header" to="/">
              Contact
            </NavLink>
          </div>
        </div>

        <div className="group-button">
          <button className="btn-sign-in" onClick={onDisConnect}>
            {formatCode(userData, 5, 5)}
          </button>

          <button className="d-block d-lg-none btn border border-0">
            <UnorderedListOutlined
              className="text-white"
              style={{ fontSize: 24 }}
            />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default HeaderOnLogin;
