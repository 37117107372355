import React from "react";
import { Route, Routes } from "react-router-dom";
import Affiliate from "src/pages/Affiliate";
import HeaderOnLogin from "src/pages/HeaderOnLogin";
import Home from "src/pages/Home";
import Footer from "src/pages/Home/Items/Footer";
import ListLeftItems from "src/pages/ListLeftItems";
import Mission from "src/pages/Mission";
import Wallet from "src/pages/Wallet";

const MainNavigation = () => {
  return (
    <>
      <HeaderOnLogin />
      <div className="container-xxl">
        <div className="col col-lg-12 row py-5">
          <ListLeftItems />
          <Routes>
            <Route index path="/" element={<Mission />} />
            <Route index path="/affiliate" element={<Affiliate />} />
            <Route index path="/wallet" element={<Wallet />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MainNavigation;
