import React, { useEffect } from "react";
import "./Affillate.css";
import { useAppDispatch } from "src/components/Redux/store";
import { getTree } from "src/components/Features/user/userSlice";
import { useSelector } from "react-redux";
import { userInfor } from "src/components/Redux/selector";

const Affiliate = () => {
  const dispatch = useAppDispatch();
  const userData = useSelector(userInfor);

  useEffect(() => {
    getUserTree();
  }, []);

  const getUserTree = async () => {
    const resp: any = await dispatch(getTree(userData));
    console.log("resp---", resp);
  };

  return (
    <div className="col col-md-9">
      <div className="rounded-4 shadow-lg p-3">
        <div className="d-flex gap-2 px-3">
          <div className="rounded-4 shadow-sm p-3 w-50">
            <div className="txt-info-affi">Info</div>
            <div className="d-flex align-items-center justify-content-between">
              <div>Total People invited</div>
              <div className="fw-semibold fs-5">100</div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div>F1 invited</div>
              <div className="fw-semibold fs-5">100</div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div>Total income</div>
              <div className="fw-semibold fs-5">2000 FOX</div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div>Total income system</div>
              <div className="fw-semibold fs-5">200000 FOX</div>
            </div>
          </div>
          <div className="rounded-4 w-50 shadow-sm p-3">
            <div className="txt-your-rank-affi">Your rank</div>
            <div className="pt-2">
              Receive 20% F1 FOX for completing all missions <br /> Get 10% FOX
              of F2 completing all missions <br /> Get 5% FOX of F3 completing
              all missions
            </div>
          </div>
        </div>

        <div className="py-5 fw-bolder fs-5">System</div>
        <div
          className="d-flex gap-4 rounded-3 p-3"
          style={{
            background: "#E8F7FF",
          }}
        >
          <div>Rank</div>
          <div className="d-flex align-items-center gap-2">
            <div
              style={{
                width: 24,
                height: 24,
                borderRadius: 4,
                backgroundColor: "#F64545",
              }}
            />
            <div>F1</div>
          </div>
          <div className="d-flex align-items-center gap-2">
            <div
              style={{
                width: 24,
                height: 24,
                borderRadius: 4,
                backgroundColor: "#F6C745",
              }}
            />
            <div>F2</div>
          </div>
          <div className="d-flex align-items-center gap-2">
            <div
              style={{
                width: 24,
                height: 24,
                borderRadius: 4,
                backgroundColor: "#61E5E3",
              }}
            />
            <div>F3</div>
          </div>
          <div className="d-flex align-items-center gap-2">
            <div
              style={{
                width: 24,
                height: 24,
                borderRadius: 4,
                backgroundColor: "#626AFF",
              }}
            />
            <div>F4</div>
          </div>
        </div>

        <img
          src={require("src/assets/images/affi.png")}
          className="mt-3 img-affi"
        />
      </div>
    </div>
  );
};

export default Affiliate;
