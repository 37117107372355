import { AnyAction } from "@reduxjs/toolkit";
import { useWeb3React } from "@web3-react/core";
import { message } from "antd";
import { ethers } from "ethers";
import moment from "moment";
import { Dispatch, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setUserLogin } from "src/components/Features/user/userSlice";
import { LINK_OPEN_METAMASK } from "src/constants/constants";
import Auth from "src/controllers/Auth";
import { setClientToken } from "src/controllers/Client";
import { setCookie } from "src/helpers";
import { useSignMessage } from "src/packages/wagmi/src/hooks/useSignMessage";
import { v4 as uuid } from "uuid";
import { useAccount, useConnect } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";

type ResLoginType = {
  data: {
    token: string;
  };
  success: boolean;
};

const connector = new InjectedConnector();

const callLogin = async (
  walletAddress: string,
  signature: string,
  generateUUID: string,
  dispatch: Dispatch<AnyAction>
) => {
  try {
    let params = {
      walletAddress,
      deadline: moment().unix() + 24 * 60 * 1000,
      signature,
      uuid: generateUUID,
    };

    const res: any = await Auth.login(params);

    if (res?.success) {
      dispatch(setUserLogin(params.walletAddress));
      localStorage.setItem("accessToken", res?.data?.token);
      setClientToken(res?.data?.token);
      setCookie("accessToken", res?.data?.token, 1);
      message.success("Login Success", 3);
      setClientToken(res?.data?.token);
    }
  } catch (e) {
    message.error(e?.message);
  }
};

const renderMessage = (address: string, id: string) => {
  if (address && id) {
    return `Welcome to Foxcon! Click to sign in and accept the Foxcon Terms of Service. This request will not trigger a blockchain transaction or cost any gas fees. Your authentication status will reset after 24 hours. Wallet address: ${address}. Nonce: ${id}`;
  }

  return "";
};

const useConnectWallet = () => {
  const signMessageAsync = useSignMessage();
  const dispatch = useDispatch();

  const [walletAddress, setWalletAddress] = useState<String | null>(null);
  const [isConnectMetamask, setIsConnectMetamask] = useState<boolean>(false);
  const [openSignWallet, setOpenSignWallet] = useState(false);
  const [balanceAccount, setBalanceAccount] = useState(0);

  const { account, deactivate } = useWeb3React();

  const currentAccount = useRef<string>("");

  const { connectAsync } = useConnect();
  const { address, isConnected } = useAccount();

  useEffect(() => {
    const accessTokenStorage = localStorage.getItem("accessToken");
    const walletAddressStorage = localStorage.getItem("walletAddress");

    if (accessTokenStorage && walletAddressStorage) {
      setWalletAddress(walletAddressStorage);
    }
  }, []);

  const signAndAccept = async (callback?: () => void) => {
    if (!window.ethereum) {
      return window.open(LINK_OPEN_METAMASK);
    }
    const generateUUID = uuid();

    if (!isConnected) {
      const resultConnect = await connectAsync({ connector });

      if (resultConnect) {
        try {
          const message = renderMessage(
            resultConnect?.account.toLowerCase(),
            generateUUID
          );

          const signature = await signMessageAsync({
            message,
          });

          const verify = ethers.utils.verifyMessage(message, signature || "");
          localStorage.setItem("provider", "metamask");

          if (verify) {
            await callLogin(
              resultConnect?.account,
              signature || "",
              generateUUID,
              dispatch
            );
          }

          callback?.();
        } catch (e) {
          const errMsg = e?.message?.split("(action")[0];
          message.error(errMsg || "Login failed!", 2);
          setIsConnectMetamask(false);
          callback?.();
        }
      }
    } else {
      try {
        const message = renderMessage(
          (address || "").toLowerCase(),
          generateUUID
        );

        const signature = await signMessageAsync({
          message: message,
        });

        const verify = ethers.utils.verifyMessage(message, signature || "");
        localStorage.setItem("provider", "metamask");
        if (verify) {
          await callLogin(
            address || "",
            signature || "",
            generateUUID,
            dispatch
          );
        }

        callback?.();
      } catch (e) {
        const errMsg = e?.message?.split("(action")[0];
        message.error(errMsg || "Login failed!", 2);
        setIsConnectMetamask(false);
        callback?.();
      }
    }
  };

  function checkSignConnectWallet() {
    const walletAddressStorage = localStorage.getItem("walletAddress");
    const accessTokenStorage = localStorage.getItem("accessToken");

    if (walletAddressStorage && !accessTokenStorage) {
      setWalletAddress(walletAddressStorage);
      return true;
    }
    return false;
  }

  function checkAccessToken() {
    const accessTokenStorage = localStorage.getItem("accessToken");

    if (accessTokenStorage) {
      return true;
    }
    return false;
  }

  function checkMetamaskProvider() {
    const providerStorage = localStorage.getItem("provider");
    if (providerStorage === "metamask") {
      return true;
    }
    return false;
  }

  const handleConnectWallet = async (callback?: () => void) => {
    if (!window.ethereum) {
      return window.open(LINK_OPEN_METAMASK);
    }

    try {
      setIsConnectMetamask(true);
      const walletAddressStorage = localStorage.getItem("walletAddress");

      if (!walletAddressStorage || walletAddressStorage !== walletAddress) {
        setOpenSignWallet(true);
      } else {
        signAndAccept(callback);
      }
      callback?.();

      setIsConnectMetamask(false);
    } catch (e) {
      message.error(e?.message, 3);
      setIsConnectMetamask(false);
      callback?.();
    }
  };

  return {
    handleConnectWallet,

    walletAddress,
    setWalletAddress,
    currentAccount,
    isConnectMetamask,
    checkSignConnectWallet,
    openSignWallet,
    setOpenSignWallet,
    checkMetamaskProvider,
    account,
    balanceAccount,
    deactivate,
    signAndAccept,
    checkAccessToken,
    setIsConnectMetamask,
  };
};

export default useConnectWallet;
