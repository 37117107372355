import client from "./Client";

const Auth = {
  async login(params: any) {
    try {
      const res = await client.post(`/auth/login`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async loginWeb3(params: any) {
    try {
      const res = await client.post(`/auth/AuthController_login`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async SignInWithTwitter() {
    try {
      const res = await client.get(`/auth/twitter`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async AuthTwitterCallback(params: any) {
    try {
      const res = await client.get(`/auth/twitter/callback`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getUser(params?: any) {
    try {
      const res = await client.get(`/user`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getUserMission(params?: any) {
    try {
      const res = await client.get(`/user/mission`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getUserByID(id: string | number) {
    try {
      const res = await client.get(`/user/${id}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async deleteUserByID(id: string | number) {
    try {
      const res = await client.delete(`/user/${id}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async postRole(params: any) {
    try {
      const res = await client.post(`/role`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getRole() {
    try {
      const res = await client.get(`/role`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getRoleByID(id: string | number) {
    try {
      const res = await client.get(`/role/${id}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async patchRoleByID(id: string | number) {
    try {
      const res = await client.patch(`/role/${id}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async deleteRoleByID(id: string | number) {
    try {
      const res = await client.delete(`/role/${id}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async deleteCache(id: string | number) {
    try {
      const res = await client.delete(`/role/${id}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getAffiliateByAddress(address: string | number) {
    try {
      const res = await client.get(`/affiliate/${address}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getAffiliateParentByAddress(address: string | number) {
    try {
      const res = await client.get(`/affiliate/parent/${address}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async affiliate(params: any) {
    try {
      const res = await client.post(`/affiliate`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getCommissionByAddress(address: string | number) {
    try {
      const res = await client.get(`/commission/${address}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async putCommissionWeakBranchByAddress(address: string | number) {
    try {
      const res = await client.put(`/commission/weak-branch/${address}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getAllCommission() {
    try {
      const res = await client.get(`/commission/reward/all`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async upload(params: any) {
    try {
      const res = await client.post(`/upload`, params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async uploadMulti() {
    try {
      const res = await client.post(`/upload/multi`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async uploadMultiSync() {
    try {
      const res = await client.post(`/upload/multi-sync`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getMission(params?: any) {
    try {
      const res = await client.get(`/mission`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async postMission(params: any) {
    try {
      const res = await client.post(`/mission`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getMissionMe(params?: any) {
    try {
      const res = await client.get(`/mission/me`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getMissionByID(id?: string | number) {
    try {
      const res = await client.get(`/mission/${id}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async patchMissionByID(params: any) {
    try {
      const res = await client.patch(`/mission/${params?.id}`, {
        title: params?.title,
        description: params?.description,
        reward: params?.reward,
        type: params?.type,
        redirectUrl: params?.redirectUrl,
      });
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async deleteMissionByID(id?: string | number) {
    try {
      const res = await client.delete(`/mission/${id}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async missionAssign(params?: any) {
    try {
      const res = await client.post(`/mission/assign-to`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async missionToDo(id?: string | number) {
    try {
      const res = await client.put(`/mission/to-do/${id}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async missionToClaim(params: any) {
    try {
      const res = await client.put(`/mission/to-claim/${params?.id}`, params);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },

  async getTree(walletAddress: string) {
    try {
      const res = await client.get(`/affiliate/${walletAddress}`);
      return res;
    } catch (err) {
      console.log(err?.message);
    }
  },
};

export default Auth;
