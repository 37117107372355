import { createSlice } from "@reduxjs/toolkit";

type ModalState = {
  modalConnect: { toggle: boolean };
};

const initialState = {
  modalConnect: {
    toggle: false,
  },
} as ModalState;

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModalConnect: (state, { payload }) => ({
      ...state,
      modalConnect: payload,
    }),
  },
});

const { actions, reducer } = modalSlice;
export const { setModalConnect } = actions;
export default reducer;
