import HomeImages from "src/assets/images/Home";
import MeetImages from "src/assets/images/Home/Meet";
import MainImages from "src/assets/images/Main";

export const FoxTokenFeatures = [
  {
    id: 0,
    image: HomeImages.fox_token_item_1,
    text_bold: "Bounties for referrals:",
    text: "Create a referral bounty program to expand the community by introducing new users, sharing project information, or participating in marketing activities, and reward based on their performance.",
  },
  {
    id: 1,
    image: HomeImages.fox_token_item_2,
    text_bold: "Community rewards:",
    text: "Provide a quantity of FOX tokens to those who actively contribute to the community through creating quality content, providing technical support, or participating in community building activities.",
  },
  {
    id: 2,
    image: HomeImages.fox_token_item_3,
    text_bold: "Airdrop:",
    text: "Freely distribute a certain amount of FOX tokens to users based on certain criteria, such as holding other types of tokens, or participating in the community within a specific time period.",
  },
  {
    id: 3,
    image: HomeImages.fox_token_item_4,
    text_bold: "Social events and activities:",
    text: "Organize events or social activities to promote participation and awareness of the FOX token, and provide rewards based on participation or achievements in those activities.",
  },
];

export const tokennomics = [
  {
    id: 0,
    color: "#FF3131",
    text: "30% Ecosystem - stashed away to give back to our community and build the ecosystem",
  },
  {
    id: 1,
    color: "#5E3867",
    text: "16% Airdrop: To thank those who have supported us through many Airdrops",
  },
  {
    id: 2,
    color: "#895272",
    text: "3% Advisory : Thanks to our mentor because they came up with a great idea",
  },
  {
    id: 3,
    color: "#30356D",
    text: "17% Contributors : For the partners that build wiht us",
  },
  {
    id: 4,
    color: "#CAA737",
    text: "6% Presale to the community",
  },
  {
    id: 5,
    color: "#2C8BB9",
    text: "12% Private Sale",
  },
  {
    id: 6,
    color: "#CFD541",
    text: "25% Launch Pool",
  },
];

export const dowload_documents = [
  {
    id: 0,
    image: HomeImages.documents_1,
    text: "Whitepaper",
  },
  {
    id: 1,
    image: HomeImages.documents_2,
    text: "Onepager",
  },
  {
    id: 2,
    image: HomeImages.documents_3,
    text: "Privacy Policy",
  },
  {
    id: 3,
    image: HomeImages.documents_4,
    text: "Terms of Sales",
  },
];

export const contact = [
  // {
  //   id: 0,
  //   icon: HomeImages.ic_call_faq,
  //   text: '+44 0123 4567'
  // },
  {
    id: 1,
    icon: HomeImages.ic_email_faq,
    text: "hi@foxcon.io",
  },
  // {
  //   id: 2,
  //   icon: HomeImages.ic_telegram_faq,
  //   text: 'Join us on Telegram'
  // },
];

export const social_network = [
  {
    id: 0,
    icon: HomeImages.ic_facebook,
  },
  {
    id: 1,
    icon: HomeImages.ic_insta,
  },
  {
    id: 2,
    icon: HomeImages.ic_youtube,
  },
  {
    id: 3,
    icon: HomeImages.ic_tele,
  },
  {
    id: 4,
    icon: HomeImages.ic_tele_gold,
  },
];

export const social_network_header = [
  {
    id: 0,
    icon: HomeImages.ic_tele_black,
  },
  {
    id: 1,
    icon: HomeImages.ic_tele_black,
  },
  {
    id: 2,
    icon: HomeImages.ic_tele_black,
  },
  {
    id: 3,
    icon: HomeImages.ic_tele_black,
  },
];

export const contact_text = [
  {
    id: 0,
    text: "What is ICO",
  },
  {
    id: 1,
    text: "ICO Apps",
  },
  {
    id: 2,
    text: "Join Us",
  },
  {
    id: 3,
    text: "Tokens",
  },
  {
    id: 4,
    text: "Whitepaper",
  },
  {
    id: 5,
    text: "Contact",
  },
  {
    id: 6,
    text: "Roadmap",
  },
  {
    id: 7,
    text: "Teams",
  },
  {
    id: 8,
    text: "FAQ",
  },
];

export const list_meet = [
  {
    id: 0,
    image: MeetImages.meet_1,
  },
  {
    id: 1,
    image: MeetImages.meet_2,
  },
  {
    id: 2,
    image: MeetImages.meet_3,
  },
  {
    id: 3,
    image: MeetImages.meet_4,
  },
  {
    id: 4,
    image: MeetImages.meet_5,
  },
  {
    id: 5,
    image: MeetImages.meet_6,
  },
  {
    id: 6,
    image: MeetImages.meet_7,
  },
  {
    id: 7,
    image: MeetImages.meet_8,
  },
  {
    id: 8,
    image: MeetImages.meet_9,
  },
  {
    id: 9,
    image: MeetImages.meet_10,
  },
];

export const listLeft = [
  {
    id: 0,
    text: "Mission",
    icon: MainImages.mission,
  },
  {
    id: 1,
    text: "Affiliate",
    icon: MainImages.affilate,
  },
  {
    id: 2,
    text: "Wallet",
    icon: MainImages.wallet,
  },
];
