import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectLoading } from "../Redux/selector";
import { Modal, Upload, UploadProps, message } from "antd";
import { useAppDispatch } from "../Redux/store";
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import { toast } from "react-toastify";
import { missionToClaim, upload } from "../Features/user/userSlice";
import "./Modal.css";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const ModalUpload = (props: any) => {
  const { isOpen, isClose, chooseItem } = props;
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [file, setFile] = useState<any>(null);

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setFile(info.file.originFileObj);
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Choose image</div>
    </div>
  );

  const onSubmit = async () => {
    const formData: any = new FormData();
    formData.append("file", file);
    const resp: any = await dispatch(upload(formData));
    console.log("resp upload", resp);
    if (resp?.payload?.success) {
      const params = {
        id: chooseItem?.missions?.id,
        linkShares: [resp?.payload?.data],
        user: chooseItem?.userId,
      };
      const res: any = await dispatch(missionToClaim(params));
      console.log("mission to claim-----", res);
      if (resp?.payload?.success) {
        isClose();
      }
    }
  };
  return (
    <Modal
      title={"Take photos of evidence"}
      open={isOpen}
      footer={null}
      closeIcon={null}
      onCancel={() => isClose()}
      width={360}
    >
      <div className="d-flex flex-column align-items-center">
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
          ) : (
            uploadButton
          )}
        </Upload>
        <button
          className="btn-submit-recharge mt-2 p-2 d-flex"
          onClick={onSubmit}
        >
          Hoàn tất
        </button>
      </div>
    </Modal>
  );
};

export default ModalUpload;
