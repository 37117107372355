import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setClientToken } from "src/controllers/Client";
import Auth from "src/controllers/Auth";

const userInitialState = {
  dataLogin: {},
  firstLaunchApp: false,
  fromDeeplink: false,
};

export const SignInWithTwitter = createAsyncThunk(
  "user/SignInWithTwitter",
  async () => {
    const resp = await Auth.SignInWithTwitter();
    return resp;
  }
);

export const login = createAsyncThunk("user/login", async (params: any) => {
  const resp = await Auth.login(params);
  return resp;
});

export const loginWeb3 = createAsyncThunk(
  "user/loginWeb3",
  async (params: any) => {
    const resp = await Auth.loginWeb3(params);
    return resp;
  }
);

export const AuthTwitterCallback = createAsyncThunk(
  "user/AuthTwitterCallback",
  async (params: any) => {
    const resp = await Auth.AuthTwitterCallback(params);
    return resp;
  }
);

export const getUser = createAsyncThunk("user/getUser", async (params: any) => {
  const resp = await Auth.getUser(params);
  return resp;
});

export const getUserMission = createAsyncThunk(
  "user/getUserMission",
  async (params: any) => {
    const resp = await Auth.getUserMission(params);
    return resp;
  }
);

export const getUserByID = createAsyncThunk(
  "user/getUserByID",
  async (id: number | string) => {
    const resp = await Auth.getUserByID(id);
    return resp;
  }
);

export const deleteUserByID = createAsyncThunk(
  "user/deleteUserByID",
  async (id: number | string) => {
    const resp = await Auth.deleteUserByID(id);
    return resp;
  }
);

export const postRole = createAsyncThunk(
  "user/postRole",
  async (params: any) => {
    const resp = await Auth.postRole(params);
    return resp;
  }
);

export const getRole = createAsyncThunk("user/getRole", async () => {
  const resp = await Auth.getRole();
  return resp;
});

export const getRoleByID = createAsyncThunk(
  "user/getRoleByID",
  async (id: string | number) => {
    const resp = await Auth.getRoleByID(id);
    return resp;
  }
);

export const patchRoleByID = createAsyncThunk(
  "user/patchRoleByID",
  async (id: string | number) => {
    const resp = await Auth.patchRoleByID(id);
    return resp;
  }
);

export const deleteRoleByID = createAsyncThunk(
  "user/deleteRoleByID",
  async (id: string | number) => {
    const resp = await Auth.deleteRoleByID(id);
    return resp;
  }
);

export const deleteCache = createAsyncThunk(
  "user/deleteCache",
  async (id: string | number) => {
    const resp = await Auth.deleteCache(id);
    return resp;
  }
);

export const getAffiliateByAddress = createAsyncThunk(
  "user/getAffiliateByAddress",
  async (id: string | number) => {
    const resp = await Auth.getAffiliateByAddress(id);
    return resp;
  }
);

export const getAffiliateParentByAddress = createAsyncThunk(
  "user/getAffiliateParentByAddress",
  async (id: string | number) => {
    const resp = await Auth.getAffiliateParentByAddress(id);
    return resp;
  }
);

export const affiliate = createAsyncThunk(
  "user/affiliate",
  async (params: any) => {
    const resp = await Auth.affiliate(params);
    return resp;
  }
);

export const getCommissionByAddress = createAsyncThunk(
  "user/getCommissionByAddress",
  async (id: string | number) => {
    const resp = await Auth.getCommissionByAddress(id);
    return resp;
  }
);

export const putCommissionWeakBranchByAddress = createAsyncThunk(
  "user/putCommissionWeakBranchByAddress",
  async (id: string | number) => {
    const resp = await Auth.putCommissionWeakBranchByAddress(id);
    return resp;
  }
);

export const getAllCommission = createAsyncThunk(
  "user/getAllCommission",
  async () => {
    const resp = await Auth.getAllCommission();
    return resp;
  }
);

export const upload = createAsyncThunk("user/upload", async (params: any) => {
  const resp = await Auth.upload(params);
  return resp;
});

export const uploadMulti = createAsyncThunk("user/uploadMulti", async () => {
  const resp = await Auth.uploadMulti();
  return resp;
});

export const uploadMultiSync = createAsyncThunk(
  "user/uploadMultiSync",
  async () => {
    const resp = await Auth.uploadMultiSync();
    return resp;
  }
);

export const getMission = createAsyncThunk("user/getMission", async () => {
  const resp = await Auth.getMission();
  return resp;
});

export const postMission = createAsyncThunk(
  "user/postMission",
  async (params: any) => {
    const resp = await Auth.postMission(params);
    return resp;
  }
);

export const getMissionMe = createAsyncThunk(
  "user/getMissionMe",
  async (params?: any) => {
    const resp = await Auth.getMissionMe(params);
    return resp;
  }
);

export const getMissionByID = createAsyncThunk(
  "user/getMissionByID",
  async (id: string | number) => {
    const resp = await Auth.getMissionByID(id);
    return resp;
  }
);

export const patchMissionByID = createAsyncThunk(
  "user/patchMissionByID",
  async (params: any) => {
    const resp = await Auth.patchMissionByID(params);
    return resp;
  }
);

export const deleteMissionByID = createAsyncThunk(
  "user/deleteMissionByID",
  async (id: string | number) => {
    const resp = await Auth.deleteMissionByID(id);
    return resp;
  }
);

export const missionAssign = createAsyncThunk(
  "user/missionAssign",
  async (params: any) => {
    const resp = await Auth.missionAssign(params);
    return resp;
  }
);

export const missionToDo = createAsyncThunk(
  "user/missionToDo",
  async (id: string | number) => {
    const resp = await Auth.missionToDo(id);
    return resp;
  }
);

export const getTree = createAsyncThunk(
  "user/getTree",
  async (walletAddress: string) => {
    const resp = await Auth.getTree(walletAddress);
    return resp;
  }
);

export const missionToClaim = createAsyncThunk(
  "user/missionToClaim",
  async (params: any) => {
    const resp = await Auth.missionToClaim(params);
    return resp;
  }
);

const user = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    setUserLogin(state, payload) {
      state.dataLogin = payload;
    },
    logOut: (state) => {
      state.dataLogin = {};
      setClientToken(null);
    },
  },
});

const { actions, reducer } = user;
export const { setUserLogin, logOut } = actions;
export default reducer;
