import { combineReducers, Reducer, AnyAction } from "@reduxjs/toolkit";
import loadingReducer from "src/components/Features/loading/loadingSlice";
import modalReducer from "src/components/Features/modal/modalSlice";
import useReducer from "src/components/Features/user/userSlice";

const combinedReducer = combineReducers({
  isLoading: loadingReducer,
  user: useReducer,
  modal: modalReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  // if (action.type === 'user/logOut') {
  //   state = {} as RootState
  // }
  return combinedReducer(state, action);
};
export default rootReducer;
