import React from "react";
import HomeImages from "src/assets/images/Home";
import { contact_text, social_network } from "src/constants/Array";

export default function Footer() {
  const onNavigate = (id: number) => {
    switch (id) {
      case 0:
        window.open("https://www.facebook.com/foxcon.fb", "_blank");
        break;
      case 1:
        window.open("https://x.com/foxconio", "_blank");
        break;
      case 2:
        window.open(
          "https://www.youtube.com/channel/UCRKS3vPzlr-fOXq6QGgjVlQ",
          "_blank"
        );
        break;
      case 3:
        window.open("https://t.me/foxconio", "_blank");
        break;
      case 4:
        window.open("https://t.me/foxcon_chat", "_blank");
        break;
      default:
        break;
    }
  };
  return (
    <div className="row">
      <div
        className="col-lg-12"
        style={{
          backgroundColor: "#261986",
        }}
      >
        <div className="py-5 container-best-features">
          <div className="row col-lg-12">
            <div className="d-flex gap-3 col-lg-5">
              {social_network.map((item) => (
                <div
                  key={item.id}
                  className="btn border border-0 p-0 btn-socical-network"
                  onClick={() => onNavigate(item.id)}
                >
                  <img src={item.icon} width={30} height={30} />
                </div>
              ))}
            </div>

            <div className="col-lg-3 txt-title-white fs-5">NEWSLETTER</div>

            <div className="col-lg-4 row">
              {contact_text.map((item) => {
                return (
                  <div className="txt-contact-right col-lg-4" key={item.id}>
                    {item.text}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="footer-logo-content">
            <img src={HomeImages.footer_logo} width={183} height={46} alt="" />
            <div className="footer-content">
              All trademarks and copyrights belong to their respective owners.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
