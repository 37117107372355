import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink } from "react-router-dom";
import logo from "src/assets/images/logo_fox.png";
import "src/styles/Navbar.css";
import pdf from "src/assets/PDF/Pitch_Deck.pdf";
import {
  DownOutlined,
  RightCircleOutlined,
  UnorderedListOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import HomeImages from "src/assets/images/Home";
import {
  FoxTokenFeatures,
  contact,
  contact_text,
  social_network,
  social_network_header,
  tokennomics,
} from "src/constants/Array";
import AOS from "aos";
import "aos/dist/aos.css";
import "src/styles/Home.css";
import { loadSlim } from "tsparticles-slim";
import { Collapse } from "react-collapse";
import road_map from "src/assets/images/roadmap.png";

export default function Home() {
  const myRef = useRef<any>(null);
  const [showText, setshowText] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);

  const onConnect = async () => {
    // dispatch(setModalConnect({ toggle: true }));
    window.open("https://dapp.foxcon.io/", "_blank");
  };

  const particlesInit = useCallback(async (engine: any) => {
    console.log(engine);
    await loadSlim(engine);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className="btn border border-0 p-0">
          <a className="btn-header-mobile" href={pdf} target="_blank">
            Pitch Deck
          </a>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() =>
            window.open("https://t.me/foxcon_airdrop_bot", "_blank")
          }
        >
          Airdrop
        </div>
      ),
    },
    {
      key: "3",
      label: <div>Token Sale</div>,
    },
    {
      key: "4",
      label: <div>Roadmap</div>,
    },
    {
      key: "5",
      label: (
        <div onClick={() => window.open("https://docs.foxcon.io", "_blank")}>
          Docs
        </div>
      ),
    },
    {
      key: "6",
      label: <div>FAQs</div>,
    },
    {
      key: "7",
      label: <div>Contact</div>,
    },
  ];

  const onNavigate = (id: number) => {
    switch (id) {
      case 0:
        window.open("https://www.facebook.com/foxcon.fb", "_blank");
        break;
      case 1:
        window.open("https://x.com/foxconio", "_blank");
        break;
      case 2:
        window.open(
          "https://www.youtube.com/channel/UCRKS3vPzlr-fOXq6QGgjVlQ",
          "_blank"
        );
        break;
      case 3:
        window.open("https://t.me/foxconio", "_blank");
        break;
      case 4:
        window.open("https://t.me/foxcon_chat", "_blank");
        break;
      default:
        break;
    }
  };

  return (
    <div className="container-homepage">
      <nav className="navbar navbar-expand-lg container-navbar">
        <div className="container-xxl py-3">
          <NavLink to={"/"}>
            <img src={logo} alt="" className="nav-bar-img" />
          </NavLink>
          <div className="w-50 d-none d-lg-block">
            <div className="d-flex justify-content-between w-100">
              <a className="btn-header" href={pdf} target="_blank">
                Pitch Deck
              </a>
              <NavLink
                className="btn-header"
                to="/"
                onClick={() =>
                  window.open("https://t.me/FoxconAirdrop_Bot/", "_blank")
                }
              >
                Airdrop
              </NavLink>
              <NavLink className="btn-header" to="/">
                Token Sale
              </NavLink>
              <NavLink className="btn-header" to="/">
                Roadmap
              </NavLink>
              <NavLink
                className="btn-header"
                to="/"
                onClick={() => window.open("https://docs.foxcon.io", "_blank")}
              >
                Docs
              </NavLink>
              <NavLink className="btn-header" to="/">
                FAQs
              </NavLink>
              <NavLink className="btn-header" to="/">
                Contact
              </NavLink>
            </div>
          </div>
          {/* <div className="d-none d-lg-block">
            <div className="d-flex gap-2">
              {social_network_header.map((item) => {
                return (
                  <div key={item.id} className="btn border border-0 p-0">
                    <img
                      src={item.icon}
                      style={{
                        width: 24,
                        height: 24,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div> */}
          <div className="group-button">
            {/* <button className="btn-sign-in" onClick={onConnect}>
              Get NFT FoxCon
            </button> */}

            <div
              className="btn border border-0 p-0"
              onClick={onConnect}
              style={{
                backgroundImage: `url(${HomeImages.btn_sign_in})`,
                width: 156,
                height: 39,
                backgroundSize: "contain",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                fontWeight: "500",
                color: "white",
                fontSize: 14,
              }}
            >
              Mint NFT
            </div>
            <Dropdown
              menu={{ items }}
              placement="bottomRight"
              className="d-block d-lg-none"
            >
              <button className="btn border border-0">
                <UnorderedListOutlined style={{ fontSize: 24 }} />
              </button>
            </Dropdown>
          </div>
        </div>
      </nav>
      <div
        className="text-white text-center fw-bolder w-100 py-4 btn border border-0 p-0"
        style={{ backgroundColor: "#17235E" }}
        onClick={() => window.open("https://t.me/FoxconAirdrop_Bot/", "_blank")}
      >
        Foxcon Airdrop: Claim your Airdrop Tokens Now!
        <RightCircleOutlined style={{ marginLeft: 8 }} />
      </div>
      {/* 
      social and web3 */}
      <div
        className="py-5"
        style={{
          background: "linear-gradient(90deg,#E5FEFC,#EAF5FE,#FEF0F8)",
        }}
      >
        <div className="container-xxl">
          <div className="row">
            <div className="container-left col-lg-7" data-aos="fade-up">
              <div className="fw-semibold fs-2">SocialFi And Web3</div>
              <div className="left-content pt-3">
                The web3 trend creates a transformation in <br /> social
                networks, creating opportunities for new <br /> business models
                and promoting the <br /> development of decentralized social
                networks.
              </div>
              <div className="d-flex justify-content-between pt-4 pb-5 container-btn-left">
                <button className="d-flex align-items-center btn-left fw-semibold">
                  <img
                    src={HomeImages.white_paper}
                    width={36}
                    height={36}
                    className="me-2"
                  />
                  White Paper
                </button>
                <button className="d-flex align-items-center btn-left fw-semibold">
                  <img
                    src={HomeImages.one_page}
                    width={36}
                    height={36}
                    className="me-2"
                  />
                  <a href={pdf} className="btn-left-pitch-deck" target="_blank">
                    Pitch Deck
                  </a>
                </button>
                <button className="d-flex align-items-center btn-left fw-semibold">
                  <img
                    src={HomeImages.how_it_work}
                    width={36}
                    height={36}
                    className="me-2"
                  />
                  How It Work
                </button>
              </div>
              <div className="d-flex gap-2 pt-2">
                <div
                  onClick={() =>
                    window.open("https://t.me/FoxconAirdrop_Bot/", "_blank")
                  }
                  className="btn border border-0 p-0"
                  style={{
                    background:
                      "linear-gradient(90deg,#00BFA9, #00A6FF,#E0B8FF)",
                    height: 72,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    gap: 8,
                    borderRadius: 40,
                    width: 250,
                  }}
                >
                  Join Airdrop <RightCircleOutlined />
                </div>
                <div className="d-none d-lg-block">
                  <div
                    className="btn border border-0 p-0"
                    style={{
                      background:
                        "linear-gradient(90deg,#00BFA9, #00A6FF,#E0B8FF)",
                      height: 72,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      gap: 8,
                      borderRadius: 40,
                      width: 230,
                    }}
                  >
                    <a
                      href={pdf}
                      className="btn-left-pitch-deck"
                      target="_blank"
                      style={{
                        color: "white",
                        fontSize: 14,
                        fontWeight: "600",
                      }}
                    >
                      Pitch Deck
                    </a>{" "}
                    <RightCircleOutlined />
                  </div>
                </div>
              </div>
              <div className="d-block d-lg-none mt-3">
                <div
                  className="btn border border-0 p-0"
                  style={{
                    background:
                      "linear-gradient(90deg,#00BFA9, #00A6FF,#E0B8FF)",
                    height: 72,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    gap: 8,
                    borderRadius: 40,
                    width: 230,
                  }}
                >
                  <a
                    href={pdf}
                    className="btn-left-pitch-deck"
                    target="_blank"
                    style={{
                      color: "white",
                      fontSize: 14,
                      fontWeight: "600",
                    }}
                  >
                    Pitch Deck
                  </a>{" "}
                  <RightCircleOutlined />
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-none d-lg-block" data-aos="fade-up">
              <img src={HomeImages.img_right} className="img-right" />
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl">
        <div className="py-5 row">
          <div className="col-lg-6">
            <img
              src={HomeImages.img_left_what_is_fox}
              width={500}
              height={400}
              className="img-wif"
              data-aos="fade-up"
            />
          </div>
          <div data-aos="fade-up" className="col-lg-6 ps-5">
            <div className="d-flex align-items-center gap-2">
              <div className="border-minus" />
              <div className="txt-title-wif">What is fox?</div>
            </div>
            <div className="txt-title-white">
              We have built a socialFi and <br /> Web3 platform
            </div>
            <div className="txt-content">
              FOX is a platform for the future of funding that powering dat{" "}
              <br /> for the new equity blockchain.
            </div>
            <div
              className={showText ? "txt-content-2" : "txt-content-2-read-more"}
            >
              While existing solutions offer to solve just one problem at a
              time, our team is up to build a secure, useful, & easy-to-use
              product based on private blockchain. It will include easy
              cryptocurrency payments integration, and even a digital
              arbitration system. One solution to combine social media and Web3
              is to build decentralized social media platforms based on
              blockchain technology and smart contracts. This would allow users
              to have complete control over their data, verify, and delegate
              access rights. Features such as communication, content sharing,
              and community building can be built on this decentralized system,
              creating a safer, more transparent, and honest social media
              environment. Cryptocurrency or tokens could also be utilized to
              reward users for contributing or creating quality content on the
              platform, thus stimulating and increasing interaction within the
              community. At the end, Our aims to integrate all companies,
              employees, and business assets into a unified blockchain
              ecosystem, which will make business truly efficient, transparent,
              and reliable.
            </div>
            <button
              onClick={() => setshowText(!showText)}
              className="border border-0 rounded-3 border-white btn p-0 pt-3"
            >
              Read more
            </button>

            <div className="d-flex gap-4 pt-2">
              <div
                className="btn border border-0 p-0"
                style={{
                  background: "linear-gradient(90deg,#00BFA9, #00A6FF,#E0B8FF)",
                  width: 205,
                  height: 72,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  gap: 8,
                  borderRadius: 40,
                }}
                onClick={() => window.open("https://foxc.io", "_blank")}
              >
                Join Social-fi <RightCircleOutlined />
              </div>
              <div className="d-none d-md-block">
                <button className="btn-apply-for">
                  <div
                    onClick={() =>
                      window.open("https://t.me/foxcon_chat", "_blank")
                    }
                    className="txt-linear-apply"
                  >
                    Join Telegram
                  </div>{" "}
                  <img src={HomeImages.ic_tele_gold} width={30} height={30} />
                </button>
              </div>
            </div>
            <div className="d-block d-md-none mt-3">
              <button className="btn-apply-for">
                <div className="txt-linear-apply">
                  Apply for an Ecosystem Grant
                </div>{" "}
                <RightCircleOutlined />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl">
        <div className="row justify-content-around">
          <div className="col-lg-6">
            <div
              className=" py-3 px-4 rounded-3"
              style={{
                background: "linear-gradient(90deg,#EAFCFE,#EBF7FF)",
              }}
            >
              <div className="d-flex align-items-center gap-2">
                <div className="border-minus" />
                <div className="txt-title-wif">GLOGAL COMMUNITY</div>
              </div>
              <div className="py-2 fw-semibold fs-4">
                Developing the world community
              </div>
              <div>
                FOX needs to develop a global community because the main goal of
                the token is to create a decentralized financial ecosystem,
                providing equal financial access for everyone worldwide.
                Developing a global community opens up opportunities for users
                everywhere, creating transparency, trust, and participation
                rights in platform governance. Global diversity and scale also
                enhance the value of the FOX token, facilitating large
                transaction volumes and sustainable growth of a fair and
                inclusive platform.
                <br />
                1.Airdrop <br />
                2.Bonus <br />
                3.Pre - Sale <br />
                4.Listing Exchange
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="py-3 px-4 rounded-3"
              style={{
                background: "linear-gradient(90deg,#EAFCFE,#EBF7FF)",
              }}
            >
              <div className="d-flex align-items-center gap-2">
                <div className="border-minus" />
                <div className="txt-title-wif">GLOGAL COMMUNITY</div>
              </div>
              <div className="py-2 fw-semibold fs-4">
                Developing the world community
              </div>
              <div>
                FOX needs to develop a global community because the main goal of
                the token is to create a decentralized financial ecosystem,
                providing equal financial access for everyone worldwide.
                Developing a global community opens up opportunities for users
                everywhere, creating transparency, trust, and participation
                rights in platform governance. Global diversity and scale also
                enhance the value of the FOX token, facilitating large
                transaction volumes and sustainable growth of a fair and
                inclusive platform.
                <br />
                1.Airdrop <br />
                2.Bonus <br />
                3.Pre - Sale <br />
                4.Listing Exchange
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl">
        <div className="row pt-3 justify-content-around">
          <div className="col-lg-6">
            <div
              className="py-3 px-4 rounded-3"
              style={{
                background: "linear-gradient(90deg,#EAFCFE,#EBF7FF)",
              }}
            >
              <div className="d-flex align-items-center gap-2">
                <div className="border-minus" />
                <div className="txt-title-wif">ENSURE A SAFE</div>
              </div>
              <div className="py-2 fw-semibold fs-4">
                Adaptive Smart Contracts
              </div>
              <div>
                To create a separate blockchain corresponding to the proof
                converted from the FOX token on BEP20 technology, you first need
                to use EVM (Ethereum Virtual Machine) technology to build a
                blockchain. BEP20 is a BEP20 token standard on the Binance Smart
                Chain (BSC) network, so creating a separate and corresponding
                blockchain for the FOX token would require writing
                standard-compliant smart contracts BEP20 and implementation of
                Binance Smart Chain protocols. Develop smart contracts
                corresponding to the BEP20 token standard, including basic
                functions such as transfers, balance checks, and transaction
                updates. Deploy these smart contracts on the Binance Smart Chain
                network for BEP20 compatibility. Build a blockchain that
                operates independently or linked to the Binance Smart Chain
                network. This separate blockchain implementation will require
                assurance of security, trustworthiness, and compatibility with
                the BEP20 token standard and Binance Smart Chain to ensure
                interoperability with the FOX token and exchanges. corresponding
                translation on Binance Smart Chain.
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="py-3 px-4 rounded-3"
              style={{
                background: "linear-gradient(90deg,#EAFCFE,#EBF7FF)",
              }}
            >
              <div className="d-flex align-items-center gap-2">
                <div className="border-minus" />
                <div className="txt-title-wif">ENSURE A SAFE</div>
              </div>
              <div className="py-2 fw-semibold fs-4">
                Adaptive Smart Contracts
              </div>
              <div>
                To create a separate blockchain corresponding to the proof
                converted from the FOX token on BEP20 technology, you first need
                to use EVM (Ethereum Virtual Machine) technology to build a
                blockchain. BEP20 is a BEP20 token standard on the Binance Smart
                Chain (BSC) network, so creating a separate and corresponding
                blockchain for the FOX token would require writing
                standard-compliant smart contracts BEP20 and implementation of
                Binance Smart Chain protocols. Develop smart contracts
                corresponding to the BEP20 token standard, including basic
                functions such as transfers, balance checks, and transaction
                updates. Deploy these smart contracts on the Binance Smart Chain
                network for BEP20 compatibility. Build a blockchain that
                operates independently or linked to the Binance Smart Chain
                network. This separate blockchain implementation will require
                assurance of security, trustworthiness, and compatibility with
                the BEP20 token standard and Binance Smart Chain to ensure
                interoperability with the FOX token and exchanges. corresponding
                translation on Binance Smart Chain.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl">
        <div className="col-lg-12">
          <div className="py-5 container-best-features">
            <div className="d-flex align-items-center gap-2">
              <div className="border-minus" />
              <div className="txt-title-wif">HOW IT WORK</div>
            </div>
            <div className="txt-title-white">Best Features</div>
            <div className="txt-content-2 col-xl-5">
              The FOX community that is passionate about making money online
              will understand the benefits of spreading the working platform to
              everyone
            </div>

            <div className="pt-5 row justify-content-md-center">
              {[1, 2, 3].map((item, index) => {
                return (
                  <div
                    key={index}
                    className="item-best-features me-5 mt-3 col-lg"
                    data-aos="fade-up"
                  >
                    <img
                      src={HomeImages.ic_best_features}
                      className="image-best-features"
                    />
                    <div className="txt-title-item-features">
                      Ultra Fast & Secure
                    </div>
                    <div className="txt-content-item-features">
                      Instant Private Transaction
                    </div>
                    <div className="border-minus" />
                    <div className="txt-content-2-item-features">
                      Millions of datasets indexedin culpa qui officia id est
                      laborum
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex justify-content-center gap-4 pb-5">
            <button className="btn-get-fox fw-semibold" data-aos="fade-up">
              Get fox token
            </button>
            <button
              className="btn-dowload-whitepaper fw-semibold"
              data-aos="fade-up"
            >
              Download Whitepaper
            </button>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${HomeImages.bg_where_to_day})`,
          height: 522,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          marginBottom: 64,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div className="container-xxl">
          <div className="text-white fs-1 fw-semibold">Where We Are Today</div>
          <div className="text-white">
            The suite of core Foxcon products provide all of the fundamental
            functionality for all Modular and Zero-knowledge features in the
            network.
          </div>
          <button className="btn-road-map">
            View the Roadmap <RightCircleOutlined />
          </button>
        </div>
      </div>

      <div className="container-xxl">
        <div className="col-lg-12">
          <div className="py-5 container-best-features">
            <div className="d-flex align-items-center gap-2">
              <div className="border-minus" />
              <div className="txt-title-wif" data-aos="fade-up">
                FOX token FEATURE
              </div>
            </div>
            <div className="txt-title-white" data-aos="fade-up">
              Give up ownership of the development team's FOX Token
            </div>
            <div className="txt-content-2 col-xl-5" data-aos="fade-up">
              These distribution approaches can create strong incentives to
              generate interest and participation from the community in using
              and developing the FOX token.
            </div>

            <div className="row justify-content-md-center gap-3 mt-3">
              {FoxTokenFeatures.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className="item-fox-token col-lg-5"
                    data-aos="fade-up"
                  >
                    <img
                      src={item.image}
                      width={160}
                      height={110}
                      className="img-item-fox-token"
                    />
                    <div className="ps-4">
                      <div className="txt-content-bold-fox-token">
                        {item.text_bold}
                      </div>
                      <div className="txt-content-fox-token">{item.text}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-lg-12"
        style={{
          backgroundColor: "#F0FCFF",
        }}
      >
        <div className="container-xxl">
          <div className="py-5 container-best-features">
            <div className="d-flex align-items-center gap-2">
              <div className="border-minus" />
              <div className="txt-title-wif">FOX Mainet</div>
            </div>
            <div className="txt-title-white">Introducing FOX Coin Mainnet:</div>
            <div className="txt-content-2">
              OX Coin Mainnet marks a significant milestone in the evolution of
              our blockchain ecosystem. Transitioning from the testnet to the
              mainnet signifies the official deployment and widespread
              availability for real-world use. Built on the foundation of
              reliability, security, and scalability, FOX Coin Mainnet offers a
              robust framework for executing real transactions securely and
              efficiently.
            </div>

            <div className="pt-5 row justify-content-around">
              <img
                src={HomeImages.img_foxmainet}
                className="img_fox_mainet col-xl-5"
                data-aos="fade-up"
              />
              <div className="col-xl-7 pt-3" data-aos="fade-up">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={HomeImages.ic_success_fox_mainet}
                    width={36}
                    height={36}
                  />
                  <div className="txt-content-fox-mainet">
                    Real-World Transactions: FOX Coin Mainnet provides the
                    platform for real-world transactions, allowing users to
                    engage in secure and verified exchanges using the FOX Coin
                    cryptocurrency.
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center pt-4">
                  <img
                    src={HomeImages.ic_success_fox_mainet}
                    width={36}
                    height={36}
                  />
                  <div className="txt-content-fox-mainet">
                    Enhanced Performance: With a focus on optimizing
                    performance, the FOX Coin Mainnet is designed to handle a
                    higher throughput of transactions, ensuring a reliable and
                    responsive network.
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center pt-4">
                  <img
                    src={HomeImages.ic_success_fox_mainet}
                    width={36}
                    height={36}
                  />
                  <div className="txt-content-fox-mainet">
                    Decentralized Operations: As a decentralized blockchain
                    network, the FOX Coin Mainnet empowers users with the
                    ability to participate, validate, and secure transactions
                    without relying on a central authority.
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center pt-4">
                  <img
                    src={HomeImages.ic_success_fox_mainet}
                    width={36}
                    height={36}
                  />
                  <div className="txt-content-fox-mainet">
                    Support for Decentralized Applications (DApps): Offering
                    support for DApps, the FOX Coin Mainnet enriches the
                    ecosystem by enabling developers to build and deploy a
                    variety of decentralized applications, fostering innovation
                    and functionality.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl">
        <div className="col-lg-12">
          <div className="py-5 container-best-features" data-aos="fade-up">
            <div className="d-flex align-items-center gap-2">
              <div className="border-minus" />
              <div className="txt-title-wif">TOKEN TOKENOMICS</div>
            </div>
            <div className="txt-title-white">The coin distribution of FOX</div>
            <div className="txt-content-2 col-xl-5">
              At the end of the Testnet process, we will execute the Mainnet and
              proceed to determine the maximum number of FOX Coins and
              distribute as follows:
            </div>
            <div className="text-white fw-semibold fs-4 py-4">
              Token Allocation
            </div>
            <div className="row col-xl-12">
              <div className="col-xl-5">
                <img
                  src={HomeImages.chart_token_allocation}
                  className="img-chart-token"
                />
              </div>

              <div className="col-xl-7">
                {tokennomics.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      className="d-flex align-items-center gap-3 mb-2"
                    >
                      <div
                        style={{
                          backgroundColor: item.color,
                          width: 32,
                          height: 32,
                          minWidth: 32,
                        }}
                      />
                      <div className="txt-chart">{item.text}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-lg-12"
        style={{
          background: "linear-gradient(90deg,#EAFCFE,#EBF7FF,#FFF0F8)",
        }}
      >
        <div className="container-xxl">
          <div className="py-5 container-best-features" data-aos="fade-up">
            <div className="d-flex align-items-center gap-2">
              <div className="border-minus" />
              <div className="txt-title-wif">TIMELINE</div>
            </div>
            <div className="txt-title-white">Road Map</div>
            <div className="txt-content-2">
              Our team is working hard to create the most suitable Blockchain
              platform for SocialFi and Web3, look forward to seeing what great
              things our team brings.
            </div>
            <img src={road_map} className="img-road-map" />
          </div>
        </div>
      </div>

      <div className="container-xxl">
        <div className="col-lg-12">
          <div
            className="py-5 container-best-features row justify-content-around"
            data-aos="fade-up"
          >
            <div className="col-lg-7">
              <div className="d-flex align-items-center gap-2">
                <div className="border-minus" />
                <div className="txt-title-wif">FAQ</div>
              </div>
              <div className="txt-title-white">Frequently asked questions</div>
              <div className="txt-content-2">
                Below we’ve provided a bit of ICO, ICO Token, cryptocurrencies,
                and few others. If you have any other questions, please get in
                touch using the contact form below.
              </div>

              <button
                onClick={() => setOpen(!open)}
                className="container-collapse mt-5"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title-collapse">
                    What is the FoxCon project set up to do?
                  </div>
                  {open ? (
                    <UpOutlined style={{ color: "#08bcf0" }} />
                  ) : (
                    <DownOutlined style={{ color: "#08bcf0" }} />
                  )}
                </div>
                <Collapse isOpened={open}>
                  <div className="content-collapse text-start">
                    Foxcon is a technology project established with the purpose
                    of promoting the development of Blockchain and applying
                    Blockchain technology to life (Real assets on Blockchain).
                    Includes the following ecosystems:
                    <br />
                    NFT Collection:
                    <br />• Foxcon is focused on developing a special collection
                    of NFTs, which are not only digital works of art but also
                    symbols of rights and shares in the project. Owning a Foxcon
                    NFT is not only aesthetic, but also a way for the community
                    to show its commitment and association with the development
                    of the project. <br />
                    Decentralized social network (Social-Fi): <br />• Foxcon's
                    Social-Fi feature is not just a regular social platform but
                    also an opportunity to earn money. Users can not only
                    interact with the community but also participate in
                    income-generating activities and utilize their free time
                    effectively.
                    <br />
                    New generation decentralized exchange:
                    <br />• Foxcon introduces a new generation decentralized
                    exchange where users can experience efficient trading with
                    low fees. This floor not only helps users choose potential
                    projects but also increases transaction speed, quickly
                    meeting transaction needs in the community.
                    <br />
                    Decentralized Wallets and Validators:
                    <br />• To ensure safety and security, Foxcon integrates
                    decentralized wallets and Validators in its ecosystem. This
                    helps users perform transactions securely and reliably,
                    while controlling and authenticating transaction processes.
                    <br />
                    Game-Fi:
                    <br />• Foxcon is not only a cryptocurrency project, but
                    also a place where the gaming community can turn their
                    passion for gaming into a source of income. Provides
                    opportunities for players to leverage their skills and earn
                    money from participating in Game-Fi related events and
                    activities.
                    <br />
                    All of the above features are tightly integrated within the
                    Foxcon platform, with the main goal of creating a diverse
                    and safe environment for users, while promoting positive
                    development in its community.
                  </div>
                </Collapse>
              </button>

              <button
                onClick={() => setOpen1(!open1)}
                className="container-collapse mt-3"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title-collapse">
                    How can users make money in the FoxCon project?
                  </div>
                  {open1 ? (
                    <UpOutlined style={{ color: "#08bcf0" }} />
                  ) : (
                    <DownOutlined style={{ color: "#08bcf0" }} />
                  )}
                </div>
                <Collapse isOpened={open1}>
                  <div className="content-collapse text-start">
                    The Foxcon project creates opportunities for users to make
                    money in many different areas, including:
                    <br />
                    NFT (Non-Fungible Token) Collection:
                    <br />• Users can earn through owning and trading NFTs in
                    their collection. NFTs can be bought and sold on Foxcon's
                    internal marketplace or other NFT exchanges.
                    <br /> Decentralized social network (Social-Fi): <br />•
                    Foxcon can provide opportunities for users to earn money
                    through interacting and participating in activities on the
                    decentralized social network. Programs or tasks can be
                    designed to ensure users receive financial rewards when they
                    perform social activities.
                    <br />
                    Decentralized Exchange (DEX): <br />• Foxcon can create
                    opportunities for users to earn through trading on the
                    project's decentralized exchange. Users can take advantage
                    of price fluctuations and trade tokens on the exchange.{" "}
                    <br />
                    Game-Fi: <br />• For gamers, Foxcon provides an opportunity
                    to turn their passion for gaming into a source of income.
                    Players can make money through participating in events,
                    tournaments, or selling/trading in-game objects. <br />
                    Benefits and special offers from NFT:
                    <br />• Those who own NFTs in the Foxcon collection can be
                    given special benefits and incentives during events,
                    promotions or other activities within the project's
                    ecosystem. <br />
                    In short, Foxcon creates a diverse and integrated ecosystem,
                    providing earning opportunities from many different aspects,
                    from owning NFTs to participating in social activities,
                    trading on DEX, Come play games and many other
                    opportunities.
                  </div>
                </Collapse>
              </button>

              <button
                onClick={() => setOpen2(!open2)}
                className="container-collapse mt-3"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title-collapse">
                    Why do I need to own a FoxCon NFT?
                  </div>
                  {open2 ? (
                    <UpOutlined style={{ color: "#08bcf0" }} />
                  ) : (
                    <DownOutlined style={{ color: "#08bcf0" }} />
                  )}
                </div>
                <Collapse isOpened={open2}>
                  <div className="content-collapse text-start">
                    Profit Sharing from Foxcon Social Network Account Sales:
                    <br />• NFT holders can profit through selling their Foxcon
                    social media accounts. This can include accounts that have
                    high authority, achieve high levels of community engagement,
                    or contain valuable content. <br />
                    NFT Price Increase: <br />• If the value and rarity of NFTs
                    increases over time, owners may take advantage of the
                    opportunity to sell NFTs for a higher price than when they
                    were purchased. <br />
                    FOX Token Price Increase: <br />• The development and
                    success of the Foxcon project may lead to an increase in the
                    price of the FOX Token. NFT holders will directly benefit
                    from holding FOX Tokens and being able to convert them into
                    financial value.
                    <br />
                    Revenue Sharing from Advertising Sales:
                    <br />• NFT holders can enjoy a share of the revenue from
                    advertising activities on the Foxcon social network. This
                    creates an incentive to create and share valuable content,
                    attracting high advertising sales. <br />
                    Receive Airdrops from Other Projects and Projects List on
                    FoxCon Platform:
                    <br />• Foxcon can organize Airdrop campaigns, and NFT
                    holders will have the opportunity to receive FOX Tokens or
                    other tokens. At the same time, if Foxcon integrates other
                    projects on its platform, NFT holders can also receive
                    Airdrops from these projects.
                    <br />
                    In total, owning NFTs in the Foxcon project not only brings
                    artistic value and special benefits, but also creates many
                    monetization opportunities from various sources in the
                    project's ecosystem.
                  </div>
                </Collapse>
              </button>

              <button
                onClick={() => setOpen3(!open3)}
                className="container-collapse mt-3"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title-collapse text-start">
                    When will Foxcon's NFTs increase in price? On what basis
                    will Foxcon's NFTs increase in price?
                  </div>
                  {open3 ? (
                    <UpOutlined style={{ color: "#08bcf0" }} />
                  ) : (
                    <DownOutlined style={{ color: "#08bcf0" }} />
                  )}
                </div>
                <Collapse isOpened={open3}>
                  <div className="content-collapse text-start">
                    Predicting when Foxcon's NFTs will increase in price is
                    complex and depends on many factors. Here are some grounds
                    that could influence the price increase of NFTs in the
                    Foxcon project:
                    <br />
                    Rare Supply and Effects: <br />• If NFTs are designed to
                    have a rare effect and limited supply, this can create
                    uniqueness and high value. If the community sees the value
                    and rarity of NFTs, demand for ownership may increase,
                    thereby driving up prices.
                    <br />
                    Quality Art and Design:
                    <br />• The quality and appeal of the art in NFTs can also
                    increase their value. If Foxcon has famous artists or a team
                    of talented artists to create unique and impressive works of
                    art, this could increase the value of NFTs.
                    <br />
                    Incentives and Additional Benefits: <br />• If the NFT comes
                    with special incentives or benefits within the Foxcon
                    ecosystem, such as dividends, shopping incentives, or
                    special event benefits, this could increase the price.
                    valuable and attractive to the owner.
                    <br />
                    Foxcon Project Development and Success: <br />• If the
                    Foxcon project flourishes and achieves recognition and
                    success from the community, this could create trust and
                    increase the value of NFTs. The development of the project
                    and increase in the value of the FOX token could also have a
                    positive influence on NFT value. <br />
                    Community Growth:
                    <br />• Significant growth in Foxcon's owner and interest
                    community could strengthen demand for NFTs. Community
                    interest and participation can create the power to boost NFT
                    value.
                    <br />
                    Events and Marketing Campaigns:
                    <br />• Các sự kiện đặc biệt, chiến dịch tiếp thị sáng tạo
                    có thể tạo ra sự tăng cường ngắn hạn cho giá trị NFT. Foxcon
                    có thể tổ chức các sự kiện đặc biệt hoặc chiến dịch quảng bá
                    để kích thích nhu cầu và tăng giá trị NFT. <br />
                    Note that the NFT market is constantly volatile and reacts
                    to a variety of factors.
                  </div>
                </Collapse>
              </button>
              <button
                onClick={() => setOpen4(!open4)}
                className="container-collapse mt-3"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title-collapse text-start">
                    What are the advantages of Foxcon's social network?
                  </div>
                  {open4 ? (
                    <UpOutlined style={{ color: "#08bcf0" }} />
                  ) : (
                    <DownOutlined style={{ color: "#08bcf0" }} />
                  )}
                </div>
                <Collapse isOpened={open4}>
                  <div className="content-collapse text-start">
                    Foxcon's social network can bring many attractive advantages
                    and features to users. Here are some advantages that can
                    exist in Foxcon's social network:
                    <br />
                    Integration with Project Ecosystem: <br />• Foxcon's social
                    network can be tightly integrated with other components of
                    the project's ecosystem, such as a decentralized exchange
                    (DEX), NFT collection, and other features. This creates a
                    comprehensive and consistent user experience.
                    <br />
                    Money Making Opportunities:
                    <br />• Social networks may provide opportunities for users
                    to earn money through activities such as interacting,
                    sharing content, or participating in promotions. This
                    motivates positivity and engagement within the community.
                    <br />
                    Special Benefits and Offers for Users: <br />• Users on the
                    social network can receive special benefits and offers,
                    especially if they own Foxcon NFTs. This could include event
                    benefits, online store discounts, or other benefits
                    depending on the project strategy.
                    <br />
                    Highly Interactive Features: <br />• Social networks are
                    designed to create a highly interactive environment between
                    users and the community. Features like commenting, sharing,
                    and adapting provide tools for users to connect and interact
                    easily. <br />
                    Security and Safety:
                    <br />• Foxcon's social network can put the safety and
                    security of users' personal information first. Security is
                    an important factor in building trust and commitment from
                    the community.
                    <br />
                    Compatibility with New Trends:
                    <br />• Foxcon can integrate new trends in social media,
                    such as livestreaming, short videos, or other exciting
                    features to keep the platform engaging and keep up with the
                    evolution of the social media industry. <br />
                    Note that the specific advantages of the Foxcon social
                    network may vary depending on the specific strategy and
                    development of the project.
                  </div>
                </Collapse>
              </button>
              <button
                onClick={() => setOpen5(!open5)}
                className="container-collapse mt-3"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title-collapse text-start">
                    Why can Premium accounts and Platinum accounts of Foxcon
                    social network be sold?
                  </div>
                  {open5 ? (
                    <UpOutlined style={{ color: "#08bcf0" }} />
                  ) : (
                    <DownOutlined style={{ color: "#08bcf0" }} />
                  )}
                </div>
                <Collapse isOpened={open5}>
                  <div className="content-collapse text-start">
                    The sale of Premium accounts and Platinum accounts of Foxcon
                    social network can have several reasons and benefits that
                    users can consider:
                    <br />
                    Special Offer: <br />• Premium and Platinum accounts may
                    come with special offers and benefits, such as event
                    benefits, online store discounts, or access to special
                    features. Buyers may be interested in getting these deals
                    and be willing to pay a premium for them.
                    <br />
                    Reputation and Reputation:
                    <br />• Premium and Platinum accounts can mark users'
                    commitment and active engagement with the Foxcon social
                    network. This can increase the account's value in the
                    community, and buyers may want to own accounts with high
                    reputation and authority.
                    <br />
                    Rare and Exclusive: <br />• If Premium accounts and Platinum
                    accounts have limited quantities or are available only to a
                    specific group of people, they can become rare and exclusive
                    coins. Buyers may wish to own accounts that have unique and
                    uncommon value.
                    <br />
                    Highly Interactive Features: <br />• Social networks are
                    designed to create a highly interactive environment between
                    users and the community. Features like commenting, sharing,
                    and adapting provide tools for users to connect and interact
                    easily. <br />
                    Stimulated by Internal Market:
                    <br />• If Foxcon has an internal marketplace where users
                    can sell and buy these accounts, stimulation from the
                    community could increase their value. Buyers may see value
                    in having an account that has been recognized by the
                    community.
                    <br />
                    Future Benefits:
                    <br />• It's possible that Premium and Platinum accounts are
                    designed to provide future benefits, such as access to
                    special events, future incentives, or new and unique
                    features. This could make it attractive to future buyers.{" "}
                    <br />
                    The high interactivity of Premium and Platinum accounts can
                    be appreciated because it brings many benefits and value to
                    users, and at the same time does not require them to use
                    paid advertising features. . Here are some strengths that
                    these accounts can bring and can be recommended to appear on
                    the Foxcon community:
                    <br />
                    Natural Interaction: <br />• Premium and Platinum accounts
                    often come with features and benefits that help increase
                    natural interaction from the community. This may include
                    benefits such as priority in comments, likes, or content
                    sharing, helping to create a quality and actively engaged
                    community.
                    <br />
                    No Fee Offers: <br />• Premium and Platinum accounts can be
                    designed to not require users to use paid advertising
                    features to increase engagement. This reduces the financial
                    pressure on users, while keeping the online environment free
                    from annoying ads.
                    <br />
                    Community Commitment and Enthusiasm:
                    <br />• Highly engaged accounts can be a sign of commitment
                    and enthusiasm from the community. The presence of these
                    accounts in the Foxcon community can create a positive
                    effect, encouraging interaction and participation from other
                    users.
                    <br />
                    Diversity in Content:
                    <br />• Premium and Platinum accounts can offer a variety of
                    content, especially if they come with special benefits like
                    access to exclusive events, advance review of new products,
                    or exclusive content sharing. This creates many types of
                    engaging and distinctive content.
                    <br />
                    Creating a Quality Environment:
                    <br />• Accounts with high engagement can help create a
                    quality and positive environment on the Foxcon community.
                    This can attract interest from the larger community and may
                    even attract attention from partners or brands interested in
                    quality engagement.
                    <br />
                    In short, Premium and Platinum accounts are not only a
                    source of engagement but also contribute to building and
                    maintaining an active and diverse online community. This can
                    increase value for both account owners and the Foxcon
                    community.
                  </div>
                </Collapse>
              </button>
              <button
                onClick={() => setOpen6(!open6)}
                className="container-collapse mt-3"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title-collapse text-start">
                    How many types of NFTs are there, what is the function of
                    each type? What's the difference?
                  </div>
                  {open6 ? (
                    <UpOutlined style={{ color: "#08bcf0" }} />
                  ) : (
                    <DownOutlined style={{ color: "#08bcf0" }} />
                  )}
                </div>
                <Collapse isOpened={open6}>
                  <div className="content-collapse text-start">
                    Foxcon's social network can bring many attractive advantages
                    and features to users. Here are some advantages that can
                    exist in Foxcon's social network:
                    <br />
                    There are two types of NFTs: <br />- NFT FoxCon: has a
                    maximum quantity of 1330 minted in 3 rounds <br />
                    + Round 1 mint 400 NFT with mint fee of $50/1
                    <br />
                    + Round 2 mint 430 NFT with mint fee of $70/1
                    <br />
                    + Round 3 mint 500 NFT with mint fee of $100/1
                    <br />
                    <br />
                    Benefits of owning Foxcon NFTs:
                    <br />+ Get on the Whitelist to receive airdrop tokens of
                    the project and other projects listed on the Foxcon
                    platform.
                    <br />+ Have the right to mine FOX Tokens daily.
                    <br />+ Receive a dividend of the 1330 NFT dividend package,
                    which will be announced in the official newsletter.
                    <br />+ Create a free Socialfi Premeum account and gamefi
                    account
                    <br />+ Join affiliate Socialfi to enjoy up to 60% premium
                    and Platinum account upgrade fees...
                    <br />
                    NFT FoxCon Platinum <br />+ There is only 1 mint round
                    parallel to the FoxCon NFT mint with a total of 1000 NFT
                    Platinum, mint fee is 500$/1
                    <br />
                    Interest: <br />+ Includes all the benefits of regular
                    FOxcon NFTs
                    <br />+ Receive separate dividends from the Platinum
                    dividend package
                    <br />
                    Security and Safety:
                    <br />• Foxcon's social network can put the safety and
                    security of users' personal information first. Security is
                    an important factor in building trust and commitment from
                    the community.
                    <br />
                    Compatibility with New Trends:
                    <br />• Foxcon can integrate new trends in social media,
                    such as livestreaming, short videos, or other exciting
                    features to keep the platform engaging and keep up with the
                    evolution of the social media industry. <br />
                    Note that the specific advantages of the Foxcon social
                    network may vary depending on the specific strategy and
                    development of the project.
                  </div>
                </Collapse>
              </button>
              <button
                onClick={() => setOpen7(!open7)}
                className="container-collapse mt-3"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title-collapse text-start">
                    What is the project's vision?
                  </div>
                  {open7 ? (
                    <UpOutlined style={{ color: "#08bcf0" }} />
                  ) : (
                    <DownOutlined style={{ color: "#08bcf0" }} />
                  )}
                </div>
                <Collapse isOpened={open7}>
                  <div className="content-collapse text-start">
                    The project sets the goal of building a super-application
                    technology platform in the field of Blockchain with a great
                    vision and mission. The project's vision is to transform
                    Blockchain technology from a technical aspect to an
                    important part of everyday life, bringing maximum benefits
                    to users. The project's mission includes important elements
                    such as enhancing convenience, ensuring speed, ensuring
                    safety and creating a transparent environment.
                    <br />
                    Utilities for Users: <br />• The goal is to bring
                    convenience to users through integrating Blockchain
                    technology into aspects of daily life. This can include
                    optimizing processes, minimizing complexity, and providing
                    the best user experience. <br />
                    Fast Speed: <br />• Promises fast speeds in transactions and
                    processes using Blockchain technology. This can help
                    eliminate time barriers and create a smooth and efficient
                    user experience. <br />
                    Safety and Security: <br />• Committed to ensuring the
                    highest safety and security for users in all transactions
                    and interactions on the platform. Security measures need to
                    be integrated to prevent risks and protect personal
                    information.
                    <br />
                    Transparent: <br />• Support transparency in all processes
                    and transactions. This helps build trust from the community
                    and create a transparent environment that does not hide
                    important information. Decentralized Finance: <br />•
                    Innovation in turning users' free time into a decentralized
                    source of income and finance. The project can provide
                    opportunities for users to earn money through decentralized
                    economic mechanisms and Blockchain-based applications.
                    <br />
                    Overall, the project not only aims to implement Blockchain
                    technology, but also aims to create a meaningful application
                    in the daily lives of users, while bringing long-term value
                    and benefits.
                  </div>
                </Collapse>
              </button>
              <button
                onClick={() => setOpen8(!open8)}
                className="container-collapse mt-3"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title-collapse text-start">
                    When will the token sale open?
                  </div>
                  {open8 ? (
                    <UpOutlined style={{ color: "#08bcf0" }} />
                  ) : (
                    <DownOutlined style={{ color: "#08bcf0" }} />
                  )}
                </div>
                <Collapse isOpened={open8}>
                  <div className="content-collapse text-start">
                    After the Mint community runs out of NFTs and the airdrops
                    end: Community on Telegram channel, X, reaches at least
                    100,000 members. After the Foxcon social network was put
                    into use.
                  </div>
                </Collapse>
              </button>
              <button
                onClick={() => setOpen9(!open9)}
                className="container-collapse mt-3 w-100"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="title-collapse text-start">
                    Stage after minting NFT (can be done simultaneously)
                  </div>
                  {open9 ? (
                    <UpOutlined style={{ color: "#08bcf0" }} />
                  ) : (
                    <DownOutlined style={{ color: "#08bcf0" }} />
                  )}
                </div>
                <Collapse isOpened={open9}>
                  <div className="content-collapse text-start">
                    Stage after NFT sale
                    <br />
                    - Build a social network with the topic of Crypto
                    <br />
                    - Build Gamefi
                    <br />
                    - Airdrop Token FOX
                    <br />
                    - Pre-sale of FOX Tokens
                    <br />
                    - Saking Token FOX
                    <br />
                    - Building trading floors and Web3
                    <br />
                  </div>
                </Collapse>
              </button>
            </div>

            <div className="col-lg-4 d-flex justify-content-center align-items-center">
              <img
                src={HomeImages.img_right_faq}
                width={350}
                height={360}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-lg-12"
        style={{
          background: "linear-gradient(90deg,#EAFEFD,#EBF8FF)",
        }}
      >
        <div className="container-xxl">
          <div className="py-5 container-best-features" data-aos="fade-up">
            <div className="d-flex align-items-center gap-2">
              <div className="border-minus" />
              <div className="txt-title-wif">FAQ</div>
            </div>
            <div className="txt-title-white">Contact</div>
            <div className="row">
              <div className="col-lg-8">
                <div className="txt-content-2 mb-5">
                  Any question? Reach out to us and we’ll get back to you
                  shortly.
                </div>
                {contact.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className="d-flex align-items-center gap-2 mt-3"
                    >
                      <img src={item.icon} width={38} height={38} />
                      <div>{item.text}</div>
                    </div>
                  );
                })}
              </div>

              <div className="col-lg-4">
                <img
                  src={HomeImages.img_contact}
                  width={350}
                  height={298}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div
          className="col-lg-12"
          style={{
            backgroundColor: "black",
          }}
        >
          <div className="container-xxl">
            <div className="py-5 container-best-features">
              <div className="row col-lg-12">
                <div className="d-flex gap-3 col-lg-5">
                  {social_network.map((item) => (
                    <div
                      key={item.id}
                      className="btn border border-0 p-0 btn-socical-network"
                      onClick={() => onNavigate(item.id)}
                    >
                      <img src={item.icon} width={30} height={30} />
                    </div>
                  ))}
                </div>

                <div className="col-lg-3 txt-title-white fs-5">NEWSLETTER</div>

                <div className="col-lg-4 row">
                  {contact_text.map((item) => {
                    return (
                      <div className="txt-contact-right col-lg-4" key={item.id}>
                        {item.text}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="footer-logo-content">
                <img
                  src={HomeImages.footer_logo}
                  width={183}
                  height={46}
                  alt=""
                />
                <div className="footer-content">© 2024 Foxmmo.io</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
