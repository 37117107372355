export const setCookie = (name: string, value: any, expDays = 0) => {
  let date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + "; " + expires + "; path=/";
};

export const formatCode = (
  text: string,
  start: number,
  end: number,
  concat = "..."
): string => {
  if (!text) return text;
  const total = start + end;
  const textStr = text.toString();
  const { length } = textStr;
  if (total >= length) return text;
  return [textStr.slice(0, start), textStr.slice(length - end)].join(concat);
};
