import React from "react";
import { useSelector } from "react-redux";
import { userInfor } from "../Redux/selector";
import { setClientToken } from "src/controllers/Client";
import MainNavigation from "./MainNavigation";
import AuthNavigation from "./AuthNavigation";
const Navigation = () => {
  const userData = useSelector(userInfor);

  //   if (userData) {
  //     setClientToken(userData?.accessToken);
  //   }
  return userData ? <MainNavigation /> : <AuthNavigation />;
};

export default Navigation;
