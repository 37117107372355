import React, { useEffect, useState } from "react";
import MissionImages from "src/assets/images/Mission";
import "./Mission.css";
import { useAppDispatch } from "src/components/Redux/store";
import {
  getAllCommission,
  getMission,
  getMissionMe,
  missionToDo,
  patchMissionByID,
} from "src/components/Features/user/userSlice";
import ModalUpload from "src/components/ModalUploadImage";

const Mission = () => {
  const dispath = useAppDispatch();
  const [allMission, setAllMission] = useState([]);
  const [file, setFile] = useState<any>(null);
  const [openUpload, setOpenUpload] = useState(false);
  const [chooseItem, setChooseItem] = useState<any>(null);

  useEffect(() => {
    getUserMission();
  }, []);

  const getUserMission = async () => {
    const resp: any = await dispath(getMissionMe({}));
    console.log("resp", resp);
    if (resp?.payload?.success) {
      const data = resp?.payload?.data?.data;
      setAllMission(data);
    }
  };

  const ToDoMission = async (item: any) => {
    // const params = {
    //   id: item?.id,
    //   title: item?.title,
    //   description: item?.description,
    //   reward: item?.reward,
    //   type: item?.type,
    //   redirectUrl: item?.redirectUrl,
    // };
    const resp: any = await dispath(missionToDo(item?.missionId));
    console.log("resp submit to do----", resp);
    if (resp?.payload?.success) {
      getUserMission();
    }

    window.open(item?.missions?.redirectUrl, "_blank");
  };

  const ToDoClaim = async (item: any) => {
    setChooseItem(item);
    setOpenUpload(true);
  };

  return (
    <>
      <div className="col col-md-5 ms-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="rounded-4 shadow-sm p-3">
            <div className="d-flex align-items-center gap-2">
              <img src={MissionImages.total_income} width={24} height={24} />
              <div className="txt-title-total">Total income</div>
            </div>
            <div className="txt-total d-flex align-items-center flex-column">
              0 FOX
              <div className="border border-2 rounded-5 w-50" />
            </div>
          </div>
          <div className="rounded-4 shadow-sm p-3">
            <div className="d-flex align-items-center gap-2">
              <img src={MissionImages.total_f1} width={24} height={24} />
              <div className="txt-title-total">Total F1 invinte</div>
            </div>
            <div className="txt-total d-flex align-items-center flex-column">
              0
              <div className="border border-2 rounded-5 w-50" />
            </div>
          </div>
          <div className="rounded-4 shadow-sm p-3">
            <div className="d-flex align-items-center gap-2">
              <img src={MissionImages.total_time} width={24} height={24} />
              <div className="txt-title-total">Total Time join</div>
            </div>
            <div className="txt-total d-flex align-items-center flex-column">
              00:00:00
              <div className="border border-2 rounded-5 w-50" />
            </div>
          </div>
        </div>

        <div className="rounded-4 shadow-sm p-3 mt-4">
          <div className="d-flex gap-2 align-items-center">
            <img
              src={MissionImages.your_mission}
              width={24}
              height={24}
              alt=""
            />
            <div className="txt-title-total">Your Mission</div>
          </div>
          {allMission.map((item: any, index: number) => {
            return (
              <div key={item?.id} className="item-mission">
                <div>
                  <div className="item-title">{item?.missions?.title}</div>
                  <div className="item-reward">
                    +{item?.missions?.reward} FOX
                  </div>
                </div>
                <button
                  disabled={item?.status === "DONE"}
                  className={
                    item?.status === "TODO"
                      ? "item-btn-submit"
                      : "item-btn-claim"
                  }
                  onClick={() => {
                    item?.status === "TODO"
                      ? ToDoMission(item)
                      : ToDoClaim(item);
                  }}
                >
                  {item?.status === "TODO"
                    ? "Do it"
                    : item?.status === "TOCLAIM"
                    ? "Claim"
                    : "Done"}
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <div className="col col-md-3">
        {/* <div className="rounded-4 shadow-sm p-3">
          <div className="text-center">
            Invite your friend to earn more NAI by sharing your referral code or
            copy this message and send to your friends.
          </div>
          <div className="bg-ref-right">
            <div className="text-white d-flex align-items-center gap-2">
              <img src={MissionImages.ref_code} width={24} height={24} alt="" />
              <div>Referral code</div>
            </div>

          </div>
        </div> */}
      </div>
      <ModalUpload
        isOpen={openUpload}
        isClose={() => setOpenUpload(false)}
        chooseItem={chooseItem}
      />
    </>
  );
};

export default Mission;
